import lozad from "lozad";
import Vue from "vue/dist/vue.esm";

import VueForm from "./form.vue";

require("../../../css/tracker/landing/common.scss");
require("../../../css/tracker/landing/menu.scss");
require("../../../css/tracker/landing/style.scss");
require("../../../css/tracker/baloo.css");

global.$ = global.jQuery = require("jquery");
require("bootstrap");

window.Vue = Vue;

global.AOS = require("aos");
AOS.init({
  offset: 60,
});

window.lozad = lozad;

const observer = lozad(); // lazy loads elements with default selector as '.lozad'
observer.observe();

document.addEventListener("DOMContentLoaded", function () {
  if (document.getElementById("locatePhone")) {
    const locateForm = new Vue({
      components: {
        vueform: VueForm,
      },
      el: "#locatePhone",
      template: "#locatePhoneTemplate",
    });
  }
});

$(".scrollup").click(function () {
  $("html, body").animate({ scrollTop: 0 }, 600);
  $("#landing_phoneGeoloc").focus();
  return false;
});
