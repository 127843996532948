import { render, staticRenderFns } from "./expand.vue?vue&type=template&id=03a37657&scoped=true"
import script from "./expand.vue?vue&type=script&lang=js"
export * from "./expand.vue?vue&type=script&lang=js"
import style0 from "./expand.vue?vue&type=style&index=0&id=03a37657&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03a37657",
  null
  
)

export default component.exports