var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "loader" }, [
    _c("div", { staticClass: "big" }, [
      _c("div", { staticClass: "spin" }, [
        _c(
          "svg",
          {
            staticClass: "icon icon-spinner",
            attrs: { "stroke-width": _vm.width },
          },
          [_c("use", { attrs: { href: "#spinner" } })]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "small" }, [
      _c("div", { staticClass: "spin" }, [
        _c(
          "svg",
          {
            staticClass: "icon icon-spinner",
            attrs: { "stroke-width": _vm.width * 1.3 },
          },
          [_c("use", { attrs: { href: "#spinner" } })]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }