<template>

  <div class="locateForm">

    <p class="hint">{{ hintText }}</p>
    <form ref="classicForm" name="landing" method="POST" v-bind:action="action">
      <input type="hidden" name="landing[_token]" :value="token">
      <input type="hidden" name="landing[phoneFull]" :value="fullNumber">
      <input type="hidden" name="landing[submit]" :value="true">
      <div class="box form">
        <div class="firstLine">
          <input
              class="input"
              :class="icon.replace('#', '')"
              ref="itiInput"
              id="landing_phoneGeoloc"
              name="landing[phoneGeoloc]"
              required="required"
              type="tel"
              autofocus="autofocus"
              v-model="number"
              data-hj-whitelist="true"
          >

          <button
              type="button"
              class="button validButton"
              :class="buttonClass"
              @click.prevent="locateModify"
              :name="buttonText"
          >
            <span>{{ buttonText }}</span>
            <span>{{ buttonTextMobile }}</span>
          </button>
        </div>

        <transition-expand>
          <div v-if="showDetails" class="details">
            <div class="whichLine">
              <template v-if="!loadingCarrier">
                <span class="carrier" v-if="carrier">
                 {{ carrierText }}&nbsp;
                  <template v-if="carrier.toLowerCase().includes('awn')">
                    &nbsp;
                    <img class="bouygues" src="/images/tracker/logos/carriers/ais.svg" alt="ais">
                  </template>
                  <template v-else-if="carrier.toLowerCase().includes('bouygues')">
                    &nbsp;
                    <img class="bouygues" src="/images/tracker/logos/carriers/bouygues.svg" alt="bouygues">
                  </template>
                  <template v-else-if="carrier.toLowerCase().includes('free')">
                    &nbsp;
                    <img class="free" src="/images/tracker/logos/carriers/free.svg" alt="free">
                  </template>
                  <template v-else-if="carrier.toLowerCase().includes('telefonica')">
                    &nbsp;
                    <img class="o2" src="/images/tracker/logos/carriers/o2.png" alt="02">
                  </template>
                  <template v-else-if="carrier.toLowerCase().includes('orange')">
                    &nbsp;
                    <img class="orange" src="/images/tracker/logos/carriers/orange.svg" alt="orange">
                  </template>
                  <template v-else-if="carrier.toLowerCase().includes('radiotelephone')">
                    &nbsp;
                    <img class="sfr" src="/images/tracker/logos/carriers/sfr.svg" alt="sfr">
                  </template>
                  <template v-else-if="carrier.toLowerCase().includes('3')">
                    &nbsp;
                    <img class="three" src="/images/tracker/logos/carriers/three.png" alt="three">
                  </template>
                  <template v-else-if="carrier.toLowerCase().includes('vodafone')">
                    &nbsp;
                    <img class="vodafone" src="/images/tracker/logos/carriers/vodafone.svg" alt="vodafone">
                  </template>
                  <template v-else-if="carrier.toLowerCase().includes('ee')">
                    &nbsp;
                    <img class="ee" src="/images/tracker/logos/carriers/ee.png" alt="ee">
                  </template>
                   <template v-else-if="carrier.toLowerCase().includes('wind')">
                    &nbsp;
                    <img class="bouygues" src="/images/tracker/logos/carriers/wind.svg" alt="wind">
                  </template>
                   <template v-else-if="carrier.toLowerCase().includes('tim')">
                    &nbsp;
                    <img class="bouygues" src="/images/tracker/logos/carriers/tim.svg" alt="tim">
                  </template>
                   <template v-else-if="carrier.toLowerCase().includes('sosh')">
                    &nbsp;
                    <img class="bouygues" src="/images/tracker/logos/carriers/sosh.svg" alt="sosh">
                  </template>
                    <template v-else-if="carrier.toLowerCase().includes('iliad')">
                    &nbsp;
                    <img class="bouygues" src="/images/tracker/logos/carriers/iliad.svg" alt="iliad">
                  </template>
                   <template v-else-if="carrier.toLowerCase().includes('poste')">
                    &nbsp;
                    <img class="bouygues" src="/images/tracker/logos/carriers/la-poste.svg" alt="iliad">
                  </template>
                  <template v-else-if="carrier.toLowerCase().includes('lycamobile')">
                    &nbsp;
                    <img class="bouygues" src="/images/tracker/logos/carriers/lycamobile.svg" alt="iliad">
                  </template>
                  <template v-else-if="carrier.toLowerCase().includes('swisscom')">
                    &nbsp;
                    <img class="bouygues" src="/images/tracker/logos/carriers/swisscom.svg" alt="iliad">
                  </template>
                  <template v-else-if="carrier.toLowerCase().includes('proximus')">
                    &nbsp;
                    <img class="bouygues" src="/images/tracker/logos/carriers/proximus.svg" alt="iliad">
                  </template>
                  <template v-else-if="carrier.toLowerCase().includes('digicel')">
                    &nbsp;
                    <img class="bouygues" src="/images/tracker/logos/carriers/digicel.svg" alt="iliad">
                  </template>
                  <template v-else-if="carrier.toLowerCase().includes('bell')">
                    &nbsp;
                    <img class="bouygues" src="/images/tracker/logos/carriers/bell-mobility.svg" alt="iliad">
                  </template>
                  <template v-else>{{ carrier }}</template>
                </span>
              </template>
            </div>

            <div class="subscribe">
              <div class="email-info">
                <svg class="icon icon-question">
                  <use xlink:href="#question"></use>
                </svg>
                <p>{{ privacyText }}</p>
              </div>
              <input
                  ref="inputEmail"
                  type="email"
                  name="landing[email]"
                  class="input"
                  :class="emailClass"
                  :placeholder="emailPlaceholderText"
                  v-model="email"
                  required="required"
                  @keyup.enter="submit"
              >
              <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
              <p v-if="correctEmail" class="error">
                {{ correctEmailText }}
                <a @click="email = correctEmail">{{ correctEmail }}</a> ?
              </p>
              <br>
              <button
                  type="button"
                  class="button button--full is-danger submit"
                  @click.prevent="submit"
              >
                &nbsp;
                <transition name="fade">
                  <loader width="2" v-if="submitting"/>
                  <span v-else>{{ geolocText }}</span>
                </transition>&nbsp;
              </button>
            </div>
          </div>
        </transition-expand>

        <transition name="fade">
          <div class="infos">
            <ul>
              <li>
                <svg class="icon icon-check">
                  <use xlink:href="#check-w"></use>
                </svg>
                <div>{{ anonymousText }}</div>
              </li>

              <li>
                <svg class="icon icon-check">
                  <use xlink:href="#check-w"></use>
                </svg>
                <div>{{ gpsText }}</div>
              </li>

              <li>
                <svg class="icon icon-check">
                  <use xlink:href="#check-w"></use>
                </svg>
                <div>{{ legalText }}</div>
              </li>
            </ul>
            <div class="social-proof" v-if="isLandingTest">
              <img src="/images/tracker/social-photos.png" alt="like">
              <p>{{ socialProofText }}</p>
            </div>
          </div>
        </transition>

        <transition name="fade">

          <div class="visit-counter" v-if="isMobileDevice || (!showDetails && visitCounter)">
            <img src="/images/tracker/fb_like.png" alt="like">
            <div><span>{{ visitCounter }}</span>{{ geolocCounterText }}</div>
          </div>
        </transition>
      </div>
    </form>
  </div>
</template>

<script>

import IntlTelInput from "intl-tel-input";
import expand from "./expand.vue";
import loader from "./loader.vue";
import axios from "axios";
import "../../../../node_modules/magnific-popup/dist/magnific-popup.css";

require("intl-tel-input/build/js/utils");
require('magnific-popup');

export default {
  props: ["action", "token", "emailUri", "phoneUri", "hint", "phonePlaceholder", "name", "privacy", "emailPlaceholder", "correctEmailSuggest", "geoloc", "anonymous", "gps", "legal", "socialProof", "geolocCounter", "update", "localise", "carrierIdentified", "isMobile", "landingTest"],
  components: {
    loader: loader,
    "transition-expand": expand
  },
  data() {
    return {
      number: this.phoneUri ? this.phoneUri : localStorage.getItem("tel"),
      iti: null,
      isValid: false,
      showDetails: false,
      submitting: false,
      loadingCarrier: false,
      carrier: "",
      carrierText: this.carrierIdentified,
      email: this.emailUri ? this.emailUri : localStorage.getItem("email"),
      correctEmail: "",
      errorMessage: "",
      visitCounter: 500 + Math.floor(Math.random() * Math.floor(50)),
      polling: null,
      hintText: this.hint,
      phonePlaceholderText: this.phonePlaceholder,
      nameText: this.name,
      privacyText: this.privacy,
      emailPlaceholderText: this.emailPlaceholder,
      correctEmailText: this.correctEmailSuggest,
      geolocText: this.geoloc,
      anonymousText: this.anonymous,
      gpsText: this.gps,
      legalText: this.legal,
      socialProofText: this.socialProof,
      geolocCounterText: this.geolocCounter,
      isMobileDevice: this.isMobile,
      isLandingTest: this.landingTest,
      multipleValidNumberCountries: ['it']
    };
  },
  computed: {
    icon() {
      let whichIcon = "#phone";
      if (this.iti !== null) {
        whichIcon =
            this.isValid && this.number !== ""
                ? "#validated"
                : !this.isValid && this.number !== ""
                    ? "#wrong"
                    : "#phone";
      }

      // if valid number
      if (this.isValid) {
        whichIcon += ' form-control is-valid';
      }

      return whichIcon;
    },
    fullNumber() {
      let number = this.number;

      if (this.iti !== null) {
        return this.iti.getNumber();
      } else {
        return "";
      }
    },
    buttonClass() {
      let className = "is-danger";

      if (this.iti !== null) {
        className =
            this.isValid && this.showDetails ? "button--outline" : "is-danger";
      }

      return className;
    },
    emailClass() {
      let className = "input";

      if (this.email !== "") {
        let isValidEmail = validateEmail(this.email);
        className = isValidEmail ? "validated" : "error";
        if (isValidEmail) {
          className += ' form-control is-valid';
        }
      }
      return className;
    },
    buttonText() {
      let text = this.localise;

      if (this.iti !== null) {
        text = this.isValid && this.showDetails ? this.update : this.localise;
      }

      return text;
    },

    buttonTextMobile() {
      let text = "Ok";

      if (this.iti !== null) {
        text = this.isValid && this.showDetails ? this.update : "Ok";
      }

      return text;
    }
  },
  watch: {
    number() {
      this.checkValid();
    },
    showDetails(val) {
      if (val) {
        this.fetchCarrier(this.iti.getNumber());
      }
    }
  },
  mounted() {
    this.itiInit();
    window.addEventListener("pageshow", ev => {
      this.itiRefresh();
    });

    this.polling = setInterval(() => {
      this.refreshVisitCounter();
    }, 3000);

    this.refreshVisitCounter();
  },
  beforeDestroy() {
    clearInterval(this.polling)
  },
  methods: {
    checkValid() {
      this.isValid = this.iti !== null ? this.iti.isValidNumber() : false;
      if (this.isValid) {
        this.resetIntlTelInput();
      }
      this.show();
    },
    submit() {
      if (this.email && this.isValid && validateEmail(this.email)) {

        localStorage.setItem("email", this.email);
        this.submitting = true;

        this.errorMessage = "";
        this.correctEmail = "";

        const url = "/valid-email";
        const params = new URLSearchParams();
        params.append('email', this.email);
        params.append('csrfToken', this.token);

        axios
            .post(url, params)
            .then(data => {
              if (data.data.result) {

                let overlay = document.getElementsByClassName('overlay')[0];
                let wrapper = document.getElementsByClassName('wrapper-video')[0];
                let video = document.getElementsByClassName('loader-video')[0];

                let li1 = document.getElementsByClassName('loader-step')[0];
                let li2 = document.getElementsByClassName('loader-step')[1];
                let li3 = document.getElementsByClassName('loader-step')[2];

                let s1 = overlay.style;
                let s2 = wrapper.style;

                s1.display = 'block';
                s2.display = 'flex';

                // needed for history.back purpose
                s1.opacity = s2.opacity = 1;

                video.play();
                $(li1).fadeIn("slow");
                setTimeout(() => {
                  $(li2).fadeIn("slow");
                }, 2000);
                setTimeout(() => {
                  $(li3).fadeIn("slow");
                }, 4000);

                setTimeout(() => {

                  s1.opacity = s2.opacity = 1;
                  (function fade() {
                    (s1.opacity -= .1) < 0 ? s1.display = 'none' : setTimeout(fade, 100)
                  })();
                  (function fade() {
                    (s2.opacity -= .1) < 0 ? s2.display = 'none' : setTimeout(fade, 100)
                  })();

                  if (this.carrier) {

                    const classicForm = this.$refs.classicForm;
                    $("#popup-carrier .carrier").html($(".whichLine .carrier").html());
                    $.magnificPopup.open({
                      preloader: false,
                      modal: true,
                      fixedContentPos: 'auto',
                      mainClass: 'mfp-carrier',
                      items: {
                        src: '#popup-carrier'
                      },
                      callbacks: {
                        open: function () {
                          classicForm.submit();
                          setTimeout(() => {
                            this.close();
                            li1.style.display = 'none';
                            li2.style.display = 'none';
                            li3.style.display = 'none';
                          }, 2000);
                        },
                      }
                    });
                  } else {
                    this.$refs.classicForm.submit();
                  }

                }, 5000);

              } else {
                this.errorMessage = data.data.message ? data.data.message : "";
                this.correctEmail = data.data.didyoumean
                    ? data.data.didyoumean
                    : "";
                //handle errors
              }
              this.submitting = false;

            })
            .catch(err => {
              console.log(err);
            });

      } else {
        this.$refs.inputEmail.focus();
      }
    },
    fetchCarrier(number) {
      if (number && !this.loadingCarrier) {
        this.loadingCarrier = true;

        const url = "/carrier";
        const params = new URLSearchParams();
        params.append('number', this.fullNumber);
        params.append('csrfToken', this.token);

        axios
            .post(url, params)
            .then(request => {
              this.loadingCarrier = false;
              if (this.$refs.inputEmail) {
                if (!this.multipleValidNumberCountries.includes(this.iti.getSelectedCountryData().iso2)) {
                  this.$refs.inputEmail.focus();
                }
              }
              if (
                  request.data && request.data["carrier"]
              ) {
                this.carrier = request.data["carrier"];
              } else {
                this.carrier = null;
              }
            })
            .catch(err => {
              console.log(err);
              if (this.$refs.inputEmail) {
                this.$refs.inputEmail.focus();
              }
            });
      }
    },
    locateModify() {
      if (this.showDetails) {
        this.showDetails = false;
        this.$refs.itiInput.focus();
      } else if (this.iti !== null) {
        if (this.isValid) {
          this.show();
        } else {
          this.$refs.itiInput.focus();
        }
      }
    },
    show() {
      this.showDetails = this.isValid;
      if (this.number !== "") {
        const number = this.iti.getNumber();
        if (this.isValid) {
          this.fetchCarrier(number);
        }
        localStorage.setItem("tel", number);
      }
    },
    resetIntlTelInput() {
      if (typeof intlTelInputUtils !== 'undefined') { // utils are lazy loaded, so must check
        let currentText = this.iti.getNumber(intlTelInputUtils.numberFormat.E164);
        if (typeof currentText === 'string') { // sometimes the currentText is an object :)
          this.iti.setNumber(currentText); // will autoformat because of formatOnDisplay=true
          this.number = this.$refs.itiInput.value;
        }
      }
    },
    itiInit() {
      this.$refs.itiInput.addEventListener("countrychange", e => {
        this.checkValid();
      });
      this.iti = IntlTelInput(this.$refs.itiInput, {
        initialCountry: "auto",
        geoIpLookup: function (callback) {
          $.get('https://ipinfo.io', {'token': '58b32311d12381'}, "jsonp").always(function (resp) {
            const countryCode = (resp && resp.country) ? resp.country : "fr";
            callback(countryCode);
          });
        },
        nationalMode: true,
        formatOnDisplay: true,
        preferredCountries: ["FR", "DE", "GB", "ES", "IT", "BE"]
      });
      this.itiRefresh();
    },
    itiRefresh() {
      setTimeout(() => {
        this.iti.setNumber(
            this.phoneUri ? this.phoneUri : localStorage.getItem("tel") ? localStorage.getItem("tel") : ""
        );
        this.number = this.$refs.itiInput.value;
        this.$refs.itiInput.focus();
      }, 100);
    },
    refreshVisitCounter() {
      this.visitCounter += Math.floor(Math.random() * Math.floor(3));
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../css/tracker/front/conf";

@keyframes appear {
  0% {
    opacity: 0;
    transform: translateY(-2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.text-center {
  text-align: center;
  width: 100%;
}

.validButton {
  @include breakpoint($phone, up) {
    span:last-child {
      display: none;
    }
  }
  @include breakpoint($phone) {
    span:first-child {
      display: none;
    }
  }
}

.whichLine {
  padding: 1rem 0;
  font-size: 1.4rem;
  @include breakpoint($phone) {
    font-size: 1.2rem;
  }
}

.locateForm {
  min-height: var(--mh);
  opacity: 0;
  transform: translateY(-2rem);
  animation: appear 0.8s $easing both;

  .hint {
    color: $color0;
    margin-bottom: 3rem;
    letter-spacing: .1rem;
    font-size: 1.5rem;

    @include breakpoint($phone) {
      text-align: center;
      font-size: 1.2rem;
    }
  }

  @include breakpoint($phone) {
    width: 100%;
    min-height: 0;
    margin-bottom: 2rem;
  }
}

.carrier {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  img {
    height: 4rem;
  }
}

.box {
  position: relative;
  max-width: 50rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.18);
  border-radius: 0.6rem;
  background-color: #ffffff;
  padding: 1.3rem 1.3rem 1.3rem 1.3rem;
  margin-top: 1rem;

  .expand-enter-active,
  .expand-leave-active {
    transition: height 0.4s $easing;
    overflow: hidden;
  }

  .expand-enter,
  .expand-leave-to {
    height: 0;
  }

}

.infos {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding-top: 2rem;
  gap: 10px;

  @include breakpoint($phone) {
    top: 110%;
  }

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @include breakpoint($phone) {
      display: list-item;
      order: 2; // Change order on smaller screens
    }
  }

  li {
    font-size: 1.1rem;
    color: #fff;
    display: flex;
    align-items: center;
    margin: 0.6rem 0;
    margin-left: 1rem;

    @include breakpoint($phone) {
      line-height: 3;
    }

    @include breakpoint($phone, up) {
      font-size: 1.3rem;

      & ~ li {
        margin: 0;
        margin-left: 3rem;
      }
    }

    svg {
      width: 1.6rem;
      height: 1.6rem;
      margin-right: 1rem;
      flex: 0 0 auto;
    }
  }

  .social-proof {
    font-size: 1.1rem;
    color: white;
    width: 83%;
    padding: 10px 0 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    order: 1; // Social proof comes first on smaller screens

    img {
      padding-left: 1rem;
      height: 35px;
    }

    @include breakpoint($phone) {
      order: 1; // Ensure it's the first item on smaller screens
      width: 100%;
      font-size: 0.8rem;
      img {
        height: 25px;
      }
    }
  }
}

.visit-counter {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 10rem;
  border-radius: 40px;
  background-color: rgba(255, 255, 255, .9);
  height: 3em;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  padding-right: 2rem;

  @include breakpoint($phone) {
    top: 110%;
    font-size: 1.2rem;
    margin-top: 16rem;
    width: inherit;
    padding-right: 1rem;
  }

  img {
    padding: 0 2rem 0 1rem;
    @include breakpoint($phone) {
      padding: 0 0.5rem 0 0.5rem;
    }
  }

  span {
    padding: 0 0.5rem 0 0;
  }
}

.firstLine {
  display: flex;
  align-items: stretch;
  justify-content: space-around;

  svg {
    width: 3rem;
    height: 3rem;
    align-self: center;
    flex: 0 0 auto;
    margin: 0 2rem 0 1rem;

    @include breakpoint($phone) {
      width: 2rem;
      margin: 0 1rem 0 0.6rem;
    }
  }

  .input {
    flex: 1 1 auto;
    padding-left: 72px;
    padding-right: 82px !important;

    @include breakpoint($bigPhone) {
      padding-left: 52px;
      padding-right: 6px;
    }
  }

  button {
    flex: 0 0 auto;
    padding: 0 4rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    @include breakpoint($phone) {
      padding: 0 2rem;
    }
  }

  .intl-tel-input .flag-container {
    padding: 0;
  }

  .selected-flag {
    border-radius: 0.6rem 0 0 0.6rem;
    background-color: #ffffff;
    color: $color2;
    border: 1px solid rgba($color2, 0.2);
    margin: 0;

    top: 1px;
    left: 1px;
    height: 96%;

    @include breakpoint($phone, up) {
      padding-left: 18px;
      width: 66px;

      .iti-arrow {
        right: 10px;
      }
    }
  }
}

.firstLine .intl-tel-input {
  flex: 1 1 auto;

  input {
    width: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-left: 80px;
  }
}

.error {
  color: $color5;
  font-weight: normal;

  a {
    text-decoration: underline;
    cursor: pointer;
  }

  @include breakpoint($phone) {
    font-size: 16px; /* min font-size for no mobile zoom */
  }
}

.subscribe {
  .email-info {
    display: flex;
    padding-right: 1rem;
    @include breakpoint($phone) {
      padding: 0;
    }

    svg {
      width: 2rem;
      height: 2rem;
      margin: 0 0.5rem 0 0;
    }

    p {
      padding-top: 0.2rem;
      font-size: 1.2rem;
      @include breakpoint($phone) {
        font-size: 1.1rem;
      }

    }
  }

  .input {
    margin: 1rem 0;
    width: 100%;
  }
}

button {
  font-size: 1.4rem;
  cursor: pointer;
  position: relative;

  span {
    display: inline-block;
  }

  &.submit {
    font-size: 1.3rem;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-2rem);
}

.loader {
  font-size: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.submit {
  position: relative;
  height: 5rem;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);

    @include breakpoint($phone) {
      white-space: nowrap;
    }
  }
}

.locateForm {

  margin-bottom: 50px !important;

  .form {
    .input {
      @include breakpoint($phone) {
        height: 46px;
      }
    }

    .visit-counter {
      display: none;
    }
  }

  .firstLine .intl-tel-input input {
    @include breakpoint($phone) {
      padding-left: 60px;
    }
  }
}
</style>
