<template>
  <div class="loader">
    <div class="big">
      <div class="spin">
        <svg class="icon icon-spinner" :stroke-width="width">
          <use href="#spinner"></use>
        </svg>
      </div>
    </div>
    <div class="small">
      <div class="spin">
        <svg class="icon icon-spinner" :stroke-width="width * 1.3">
          <use href="#spinner"></use>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      default: 2
    }
  }
};
</script>

<style lang="scss" scoped>
.loader {
  position: relative;
  width: 1em;
  height: 1em;
  color: inherit;
  display: inline-block;
  transform: translate3d(0, 0, 0);
}

.small,
.big {
  position: absolute;
  top: 50%;
  left: 50%;
  color: inherit;
  transform: translate3d(-50%, -50%, 0);

  .spin {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  svg {
    color: inherit;
    fill: transparent;
    stroke: currentColor;
    stroke-linecap: round;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.big {
  width: 1em;
  height: 1em;

  .spin {
    animation: spin 1s linear infinite forwards;
  }
}

.small {
  width: 0.7em;
  height: 0.7em;

  .spin {
    animation: spin 1.4s linear infinite reverse;
  }
}
</style>
